import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Text Input",
  "type": "Form"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Text Input`}</strong>{` component renders an input box for text input. It typically appears in
forms and dialogs. It combines an `}<inlineCode parentName="p">{`input`}</inlineCode>{` HTML element with corresponding `}<inlineCode parentName="p">{`label`}</inlineCode>{` and
success, failure messages, helper and more.`}</p>
    <p>{`The `}<strong parentName="p">{`Text Input`}</strong>{` component can be rendered in valid or invalid state, with or without
a placeholder and a label.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true",
        "live": "true",
        "editor": "true"
      }}>{`() => {
    const [email, setEmail] = React.useState(null);
    const [error, setError] = React.useState(null);

    React.useEffect( () => {
        if ( email === null ) {
            setError( null );
        } else if ( email.includes('@') ) {
            setError( null );
        } else { setError( "Valid e-mail should include @") }
    }, [email]);

    return (
        <div style={{ width: 300, margin: "auto" }}>
            <TextInput
              value={email}
              placeholder="E-mail"
              message={error}
              state={error ? "error" : email ? "success" : undefined}
              onChange={(event) => setEmail(event.target.value)}
            />
        </div>
    )
}
`}</code></pre>
    <h2>{`Visual feedback`}</h2>
    <p>{`There are several ways that `}<strong parentName="p">{`Text Input`}</strong>{` can give visual feedback of the user's input.
The visual feedback differs when the `}<strong parentName="p">{`Text Input`}</strong>{` has been rendered with or without
a `}<inlineCode parentName="p">{`label`}</inlineCode>{`.`}</p>
    <h3>{`Error`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`state="error"`}</inlineCode>{` with `}<inlineCode parentName="p">{`message`}</inlineCode>{` prop when an input has not been filled correctly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
   <TextInput
      defaultValue="foobar.com"
      placeholder="E-mail"
      message="E-mail is not valid"
      state="error"
   />
   <Separator />
   <TextInput
      label="E-mail"
      defaultValue="foobar.com"
      placeholder="E-mail"
      message="E-mail is not valid"
      state="error"
   />
</div>
`}</code></pre>
    <h3>{`Modified`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`modified`}</inlineCode>{` prop when an input has been changed and is pending to be saved.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
   <TextInput
      defaultValue="foo@bar.com"
      placeholder="E-mail"
      modified
   />
</div>
`}</code></pre>
    <h3>{`Success`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`state="success"`}</inlineCode>{` with `}<inlineCode parentName="p">{`message`}</inlineCode>{` prop when an input has been filled correctly.
The success state can also be used in case of a server side validation, providing the
feedback to the user once it is completed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
   <TextInput
      defaultValue="foo@bar.com"
      placeholder="E-mail"
      message="E-mail is available"
      state="success"
   />
   <Separator />
   <TextInput
      label="E-mail"
      defaultValue="foo@bar.com"
      placeholder="E-mail"
      message="E-mail is available"
      state="success"
   />
</div>
`}</code></pre>
    <h3>{`Message`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`message`}</inlineCode>{` prop without `}<inlineCode parentName="p">{`state`}</inlineCode>{` to provide additional rules regarding filling of
that `}<strong parentName="p">{`Text Input`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<strong parentName="p">{`message`}</strong>{` prop is only available when there is a `}<strong parentName="p">{`label`}</strong>{` set.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
   <TextInput
      label="Password"
      defaultValue="1234"
      type="password"
      message="Password must be minimum 15 characters"
   />
</div>
`}</code></pre>
    <h3>{`Helper`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`helper`}</inlineCode>{` prop to give real-time feedback of the user's input.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<strong parentName="p">{`helper`}</strong>{` prop is only available when there is a `}<strong parentName="p">{`label`}</strong>{` set.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
   <TextInput
      label="Password"
      defaultValue="1234"
      type="password"
      helper="10 characters left"
   />
</div>
`}</code></pre>
    <h2>{`Left and Right blocks`}</h2>
    <p><strong parentName="p">{`Text Input`}</strong>{` can render with left and right supplement blocks, that show typically
the value type that should be rendered ( currency, units, etc. )`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
  <TextInput
    label="VAT"
    placeholder="19"
    rightBlock="%"
    textAlign="right"
  />
  <Separator />
  <TextInput
    label="Username"
    placeholder="foo@bar.com"
    leftBlock={ <Icons.BiUser /> }
  />
</div>
`}</code></pre>
    <h2>{`Text Alignment`}</h2>
    <p><strong parentName="p">{`Text Input`}</strong>{` accepts a `}<inlineCode parentName="p">{`textAlign`}</inlineCode>{` prop that will make the content of the input field
to be rendered on the `}<inlineCode parentName="p">{`"left"`}</inlineCode>{` ( `}<em parentName="p">{`default`}</em>{` ) or `}<inlineCode parentName="p">{`"right"`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
  <TextInput
    placeholder="10"
    rightIcon={ <Icons.BiEuro /> }
    textAlign="right"
  />
</div>
`}</code></pre>
    <h2>{`Icons`}</h2>
    <p><strong parentName="p">{`Text Input`}</strong>{` supports having icons on the left and on the right side of the input
field. Use those for additional visual guidance, regarding the associated input field.
The icons are available through `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` and `}<inlineCode parentName="p">{`rightIcon`}</inlineCode>{` props.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
   <TextInput
      label="E-mail"
      defaultValue="foo@bar.com"
      leftIcon={ <Icons.BiMailSend /> }
   />
   <Separator />
   <TextInput
      label="Search"
      placeholder="Query..."
      rightIcon={ <Icons.BiSearch /> }
   />
</div>
`}</code></pre>
    <h2>{`States`}</h2>
    <p><strong parentName="p">{`Text Input`}</strong>{` passes unused props to the underlying `}<inlineCode parentName="p">{`input`}</inlineCode>{` HTML element. This gives
us the possibility to use `}<inlineCode parentName="p">{`:disabled`}</inlineCode>{`, `}<inlineCode parentName="p">{`:hover`}</inlineCode>{`, `}<inlineCode parentName="p">{`:focus`}</inlineCode>{` states.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true",
        "live": "true",
        "editor": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
  <TextInput
     label="E-mail"
     placeholder="foo@bar.com"
     disabled
  />
</div>
`}</code></pre>
    <h2>{`Props`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Text Input`}</strong>{` passes unused props to the underlying `}<inlineCode parentName="p">{`input`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Label rendered with the input field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`helper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message rendered on the right of the label ( with `}<em parentName="td">{`label`}</em>{` prop only )`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message displayed on the bottom`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"error" / "success"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of message for visual feedback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shows an orange border around`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon rendered to the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rightIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon rendered to the right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftBlock`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Block rendered on the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rightBlock`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Block rendered to the right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textAlign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left" / "right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The alignment of the text`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      